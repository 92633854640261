import { FaCloudDownloadAlt } from "react-icons/fa";
import { useEffect } from "react";

const Loading = () => {
  useEffect(() => {
    document.body.classList.add("pb-0");

    return () => {
      document.body.classList.remove("pb-0");
    };
  }, []);

  return (
    <div className="vh-100 text-center d-flex flex-column align-items-center justify-content-center">
      <div>
        <h1
          className="d-inline-block align-top"
          style={{
            fontSize: "24px",
            fontWeight: 500,
            padding: "10px 23px 10px 0",
            margin: 0,
            borderRight: "1px solid rgba(0, 0, 0,.3)",
          }}
        >
          <FaCloudDownloadAlt />
        </h1>
        <div
          className="d-inline-block align-middle"
          style={{ height: "49px", lineHeight: "49px", marginLeft: "20px" }}
        >
          <h2
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              margin: 0,
              padding: 0,
              lineHeight: "inherit",
            }}
          >
            Loading
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Loading;
