import {
  FiChevronDown,
  FiClipboard,
  FiFile,
  FiHome,
  FiKey,
  FiLogOut,
  FiMenu,
  FiUserCheck,
} from "react-icons/fi";
import { A as Link, navigate, usePath } from "hookrouter";
import {
  canViewLogbooks,
  canViewRequests,
  canViewSuppliers,
  canViewUsers,
  displayRoleName,
} from "helpers/roles";
import { useEffect, useState } from "react";

import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { currentUserState } from "states/auth";
import { useRecoilState } from "recoil";

export default function DashboardLayout({ children }) {
  const currentPath = usePath();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [sidebarEnabled, setSidebarEnabled] = useState(false);

  function isActive(path, isExact) {
    if (isExact) {
      return path === currentPath;
    }

    return currentPath.startsWith(path);
  }

  const logout = () => {
    localStorage.removeItem("access_token");
    setCurrentUser(null);
    navigate("/login");
  };

  const gotoChangePassword = () => {
    navigate("/dashboard/change-password");
  };

  useEffect(() => {
    if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      document.body.classList.add("left-side-menu-condensed");
    }

    return function cleanup() {
      document.body.classList.remove("left-side-menu-condensed");
    };
  }, []);

  useEffect(() => {
    if (sidebarEnabled) {
      document.body.classList.add("sidebar-enable");
      document.body.classList.remove("left-side-menu-condensed");
    } else {
      if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        document.body.classList.add("left-side-menu-condensed");
      }
    }

    return function cleanup() {
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("left-side-menu-condensed");
    };
  }, [sidebarEnabled]);

  if (!currentUser) {
    setTimeout(() => {
      navigate("/login");
    }, 1);

    return <></>;
  }

  return (
    <div id="wrapper">
      <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
        <div className="container-fluid">
          <Link href="/dashboard" className="navbar-brand mr-0 mr-md-2 logo">
            <span className="logo-lg">
              <img src="/images/logo-yellow.png" width="200" alt="Bromma" />
            </span>
            <span className="logo-sm">
              <img src="/images/logo-yellow.png" width="200" alt="Bromma" />
            </span>
          </Link>
          <ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
            <li>
              <button
                className="button-menu-mobile open-left disable-btn"
                onClick={() => setSidebarEnabled((v) => !v)}
              >
                <FiMenu />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="left-side-menu">
        <div className="media user-profile mt-2 mb-2">
          <img
            src="/images/default-avatar.png"
            className="avatar-sm rounded-circle mr-2"
            alt="Bromma"
          />
          <img
            src="/images/default-avatar.png"
            className="avatar-xs rounded-circle mr-2"
            alt="Bromma"
          />

          <div className="media-body">
            <h6 className="pro-user-name mt-0 mb-0">{`${currentUser.fullName}`}</h6>
            <span className="pro-user-desc">
              {displayRoleName(currentUser.role)}
            </span>
          </div>
          <Dropdown className="align-self-center profile-dropdown-menu">
            <Dropdown.Toggle as="a" className="mr-0" role="button">
              <FiChevronDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={gotoChangePassword}>
                <FiKey className="icon-dual icon-xs mr-2" />
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}>
                <FiLogOut className="icon-dual icon-xs mr-2" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="sidebar-content">
          <PerfectScrollbar>
            <div id="sidebar-menu" className="slimscroll-menu">
              <ul className="metismenu mm-show" id="menu-bar">
                <li className="menu-title">Home</li>
                <li>
                  <Link
                    href="/dashboard"
                    className={isActive("/dashboard", true) ? "active" : ""}
                  >
                    <FiHome />
                    <span> Dashboard </span>
                  </Link>
                </li>
                <li className="menu-title">e-Form</li>
                {canViewRequests(currentUser) && (
                  <li>
                    <Link
                      href="/dashboard/e-forms"
                      className={
                        isActive("/dashboard/e-forms", false) ? "active" : ""
                      }
                    >
                      <FiClipboard />
                      <span> e-Forms </span>
                    </Link>
                  </li>
                )}
                {canViewLogbooks(currentUser) && (
                  <li>
                    <Link
                      href="/dashboard/visitor-logbooks"
                      className={
                        isActive("/dashboard/visitor-logbooks", false)
                          ? "active"
                          : ""
                      }
                    >
                      <FiUserCheck />
                      <span> Visitor Logbooks </span>
                    </Link>
                  </li>
                )}
                {(canViewUsers(currentUser) ||
                  canViewSuppliers(currentUser)) && (
                  <li className="menu-title">Management</li>
                )}
                {canViewUsers(currentUser) && (
                  <li>
                    <Link
                      href="/dashboard/users"
                      className={
                        isActive("/dashboard/users", false) ? "active" : ""
                      }
                    >
                      <FiFile />
                      <span> Users </span>
                    </Link>
                  </li>
                )}
                {canViewSuppliers(currentUser) && (
                  <li>
                    <Link
                      href="/dashboard/suppliers"
                      className={
                        isActive("/dashboard/suppliers", false) ? "active" : ""
                      }
                    >
                      <FiFile />
                      <span> Suppliers </span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </PerfectScrollbar>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="content-page">
        <div className="content">{children}</div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* {new Date().getFullYear()} © Bromma. All Rights Reserved. */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
