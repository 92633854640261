import {
  RequestStatus_Approved,
  RequestStatus_External,
  RequestStatus_Rejected,
  RequestStatus_Reviewing,
  RequestStatus_Submitted,
} from "./status";

import dayjs from "dayjs";

export const Role_Admin = "Admin";
export const Role_Approver1st = "Approver1st";
export const Role_Approver2nd = "Approver2nd";
export const Role_Approver3rd = "Approver3rd";
export const Role_Security = "Security";
export const Role_Requestor = "Requestor";

export const displayRoleName = (role) => {
  switch (role) {
    case Role_Approver1st:
      return "Approver 1";
    case Role_Approver2nd:
      return "Approver 2";
    case Role_Approver3rd:
      return "Approver 3";
    default:
      return role;
  }
};

export const isApprovers = (currentUser) => {
  if (!currentUser) return false;

  return [Role_Approver1st, Role_Approver2nd, Role_Approver3rd].includes(
    currentUser.role
  );
};

export const canViewRequests = () => {
  return true;
};

export const canSubmitPVF = (pvfForm, currentUser) => {
  if (!pvfForm) return true;

  if (!currentUser) {
    return pvfForm.status === RequestStatus_External;
  }

  if (currentUser.role !== Role_Requestor) return false;

  return (
    pvfForm.status === RequestStatus_External ||
    pvfForm.status === RequestStatus_Submitted ||
    pvfForm.status === RequestStatus_Rejected
  );
};

export const canCreateRequest = (currentUser) => {
  if (!currentUser) {
    return false;
  }

  return currentUser.role === Role_Requestor;
};

export const canEditRequest = (request, currentUser) => {
  if (request.status === RequestStatus_Approved) return false;

  if (!currentUser) {
    return request.status === RequestStatus_External;
  }

  if (currentUser.role === Role_Requestor) {
    return (
      request.status === RequestStatus_External ||
      request.status === RequestStatus_Submitted ||
      request.status === RequestStatus_Rejected
    );
  }

  return false;
};

export const canSendToApproval = (request, currentUser) => {
  if (!currentUser) return false;

  if (currentUser.role !== Role_Requestor) return false;

  if (request.status === RequestStatus_Reviewing) {
    if (!request.approvedBy) {
      return true;
    }
  }

  return (
    request.status === RequestStatus_Submitted ||
    request.status === RequestStatus_Rejected
  );
};

export const canApprovalPVF = (pvfForm, currentUser) => {
  if (!currentUser) return false;

  if (pvfForm.pendingBy !== 1) return false;

  if (
    pvfForm.status === RequestStatus_Reviewing &&
    currentUser.role === Role_Approver1st
  ) {
    if (
      pvfForm.persons &&
      pvfForm.persons.some((x) => x.hdfForm?.status === RequestStatus_Rejected)
    ) {
      return false;
    }
    return true;
  }

  return false;
};

export const canAddTEDF = (pvfForm, currentUser) => {
  if (!currentUser) {
    return (
      pvfForm.status === RequestStatus_External &&
      pvfForm.tedfForms.length === 0
    );
  }

  if (currentUser.role === Role_Requestor) {
    return (
      [
        RequestStatus_External,
        RequestStatus_Submitted,
        RequestStatus_Rejected,
      ].includes(pvfForm.status) && pvfForm.tedfForms.length === 0
    );
  }

  if (currentUser.role === Role_Security) {
    return true;
  }

  return false;
};

export const canEditTEDF = (pvfForm, currentUser) => {
  if (!currentUser) {
    return pvfForm.status === RequestStatus_External;
  }

  if (currentUser.role === Role_Requestor) {
    return [
      RequestStatus_External,
      RequestStatus_Submitted,
      RequestStatus_Rejected,
    ].includes(pvfForm.status);
  }

  if (currentUser.role === Role_Security) {
    return true;
  }

  return false;
};

export const canSubmitMMF = (mmfForm, currentUser) => {
  if (!mmfForm) return true;

  if (!currentUser) {
    return mmfForm.status === RequestStatus_External;
  }

  if (currentUser.role !== Role_Requestor) return false;

  return (
    mmfForm.status === RequestStatus_External ||
    mmfForm.status === RequestStatus_Submitted ||
    mmfForm.status === RequestStatus_Rejected
  );
};

export const canApprovalMMF = (mmfForm, currentUser) => {
  if (!currentUser) return false;

  return (
    mmfForm.status === RequestStatus_Reviewing &&
    (currentUser.role === Role_Approver1st ||
      currentUser.role === Role_Approver2nd ||
      currentUser.role === Role_Approver3rd)
  );
};

export const canUpdateEntryRecord = (currentUser, record) => {
  if (!currentUser) return false;

  if (currentUser.role !== Role_Security && currentUser.role !== Role_Admin) {
    return false;
  }

  if (currentUser.role === Role_Security && record) {
    const entryTime = dayjs(record.entryTime);
    const exitTime = dayjs(record.exitTime);

    if (exitTime > entryTime) {
      const now = dayjs();
      const endToday = exitTime.endOf("day");

      if (now > endToday) {
        return false;
      }

      return true;
    }

    return true;
  }

  return true;
};

export const canViewLogbooks = (currentUser) => {
  if (!currentUser) return false;
  return [Role_Admin, Role_Security].includes(currentUser.role);
};

export const canViewUsers = (currentUser) => {
  if (!currentUser) return false;
  return [Role_Admin].includes(currentUser.role);
};

export const canViewSuppliers = (currentUser) => {
  if (!currentUser) return false;
  return [Role_Admin].includes(currentUser.role);
};

export const canDeleteRequest = (currentUser, request) => {
  if (!currentUser) return false;

  if (currentUser.role === Role_Admin) return true;

  if (currentUser.role === Role_Requestor) {
    if (request && request.status === RequestStatus_External) return true;
  }

  return false;
};
