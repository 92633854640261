import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from "@apollo/client";
import React, { Suspense, lazy } from "react";
import { useRedirect, useRoutes } from "hookrouter";

import DashboardLayout from "layouts/DashboardLayout";
import DefaultLayout from "layouts/DefaultLayout";
import Loading from "components/Loading";
import { RecoilRoot } from "recoil";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ChangePassword = lazy(() => import("./pages/dashboard/ChangePassword"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AccessDenied = lazy(() => import("./pages/AccessDenied"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Requests = lazy(() => import("./pages/dashboard/Requests"));
const PvfDetails = lazy(() => import("./pages/dashboard/PVF_Details"));
const HdfDetails = lazy(() => import("./pages/dashboard/HDF_Details"));
const TedfDetails = lazy(() => import("./pages/dashboard/TEDF_Details"));
const MmfDetails = lazy(() => import("./pages/dashboard/MMF_Details"));
const Users = lazy(() => import("./pages/dashboard/Users"));
const Suppliers = lazy(() => import("./pages/dashboard/Suppliers"));
const VisitorLogbooks = lazy(() => import("./pages/dashboard/VisitorLogbooks"));
const VisitorQRCode = lazy(() => import("./pages/VisitorQRCode"));

const routes = {
  "/": () => <Home />,
  "/login": () => <Login />,
  "/forgot-password": () => <ForgotPassword />,
  "/reset-password": () => <ResetPassword />,
  "/access-denied": () => <AccessDenied />,
  "/e-form/pvf/:id": ({ id }) => (
    <DefaultLayout>
      <PvfDetails id={id} />
    </DefaultLayout>
  ),
  "/e-form/tedf/:id": ({ id }) => (
    <DefaultLayout>
      <TedfDetails id={id} />
    </DefaultLayout>
  ),
  "/e-form/mmf/:id": ({ id }) => (
    <DefaultLayout>
      <MmfDetails id={id} />
    </DefaultLayout>
  ),
  "/e-form/hdf/:id": ({ id }) => (
    <DefaultLayout>
      <HdfDetails id={id} />
    </DefaultLayout>
  ),
  "/e-form/generate-qr": () => (
    <DefaultLayout>
      <VisitorQRCode />
    </DefaultLayout>
  ),
  "/dashboard/e-forms": () => (
    <DashboardLayout>
      <Requests />
    </DashboardLayout>
  ),
  "/dashboard/e-form/pvf/:id": ({ id }) => (
    <DashboardLayout>
      <PvfDetails id={id} />
    </DashboardLayout>
  ),
  "/dashboard/e-form/hdf/:id": ({ id }) => (
    <DashboardLayout>
      <HdfDetails id={id} />
    </DashboardLayout>
  ),
  "/dashboard/e-form/tedf/:id": ({ id }) => (
    <DashboardLayout>
      <TedfDetails id={id} />
    </DashboardLayout>
  ),
  "/dashboard/e-form/mmf/:id": ({ id }) => (
    <DashboardLayout>
      <MmfDetails id={id} />
    </DashboardLayout>
  ),
  "/dashboard/visitor-logbooks": () => (
    <DashboardLayout>
      <VisitorLogbooks />
    </DashboardLayout>
  ),
  "/dashboard/users": () => (
    <DashboardLayout>
      <Users />
    </DashboardLayout>
  ),
  "/dashboard/suppliers": () => (
    <DashboardLayout>
      <Suppliers />
    </DashboardLayout>
  ),
  "/dashboard/change-password": () => (
    <DashboardLayout>
      <ChangePassword />
    </DashboardLayout>
  ),
  "/dashboard": () => (
    <DashboardLayout>
      <Dashboard />
    </DashboardLayout>
  ),
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const access_token = localStorage.getItem("access_token");
  return {
    headers: {
      ...headers,
      authorization: access_token ? `Bearer ${access_token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    var isUnauthorized = false;
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === "Unauthorized") {
        isUnauthorized = true;
      }

      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (isUnauthorized) {
    if (window.location.pathname !== "/login") {
      window.location = "/login";
    }
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

function App() {
  useRedirect("/", "/dashboard");
  const match = useRoutes(routes);

  return (
    <RecoilRoot>
      <ApolloProvider client={apolloClient}>
        <Suspense fallback={<Loading />}>{match || <NotFound />}</Suspense>
      </ApolloProvider>
    </RecoilRoot>
  );
}

export default App;
