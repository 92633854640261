import { atom, selector } from "recoil";

import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const currentUserState = atom({
  key: "currentUserState",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const isLoggedIn = selector({
  key: "isLoggedIn",
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    return currentUser != null;
  },
});
